.date {
  background-color: #222648;
  border-radius: 50px;
  padding: 3px 20px;
  color: rgb(255, 255, 255);
}

.head {
  font-size: 18px;
}
.num {
  font-size: 23px;
  text-align: center;
}
.click {
  height: 30px;
  margin: 3px;
 padding: 50px;
 
}
