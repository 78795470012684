.dashimg {
  height: 60vh;
  overflow: hidden;
}
.timeWidth {
  width: 150px !important;
}
.react-datepicker__time-container {
  width: 150px !important;
}
