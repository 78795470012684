.logo {
  height: 200px;
  width: 200px;
  border: 3px dotted #000;
  padding: 5px;
}
.imageLogo{
    height: 200px;
    width: 200px;

}
