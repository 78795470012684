.cardDiv {
  background: #fff;
  margin-bottom: 20px;
  float: left;
  width: 100%;
  border-radius: 6px;
  border: 2px solid #eee;
  padding: 15px;
}
.cardDivtop {
  /* background-color: rgb(255, 136, 0); */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: space-around;
  /* margin-top: 80px;
  margin-left: 40px; */
}
.home {
  /* background-color: aqua; */
  display: flex;
}
.row1 {
  display: flex;
  width: 100%;
  justify-content: space-around;
}
.cardDivAss {
  background: #fff;
  margin-bottom: 20px;
  float: left;
  width: 209px;
  border-radius: 29px;
  border: 2px solid #eee;
  padding: 15px;
  height: 275px;
  background-color: #ffa04d;
}

.cardDivSess {
  background: #fff;
  margin-bottom: 20px;
  float: left;
  width: 209px;
  border-radius: 29px;
  border: 2px solid #eee;
  padding: 15px;
  height: 275px;
  background: #0747a6;
}

.cardDivMy {
  background: #fff;
  margin-bottom: 20px;
  float: left;
  width: 188px;
  height: 153px;
  border-radius: 29px;
  border: 2px solid #eee;
  padding: 15px;
  background: #fdda5f;
}
/* h4::before {
  content: "---";
  background-color: #c182f9;
  color: #c182f9;
  font-weight: bold;
  border-radius: 100%;
} */

.cardDiv1 {
  /* background-color: #ECF3FF;; */
  width: 1170px;
  height: 244px;
  top: 437px;
  left: 67px;
  radius: 6px;
  margin: 15px;
  border-radius: 6px;
}
