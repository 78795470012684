@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css);
.Dashboard_topCardLeft__1IO5S {
  float: left;
}

.Dashboard_dashimg__24k_F {
  height: 60vh;
  overflow: hidden;
}

.Dashboard_topCardLeft__1IO5S h5 {
  font-size: 14px;
  margin-bottom: 12px;
  text-align: left;
  color: #9c9c9c;
  font-weight: 400;
}

.Dashboard_topCardLeft__1IO5S h2 {
  font-size: 17px;
  margin-bottom: 10px;
  margin-top: 0;
}

.Dashboard_topCardLeft__1IO5S span {
  font-size: 12px;
  background: #222648 ;
  padding: 5px 10px;
  border-radius: 10px;
  color: #fff;
  letter-spacing: 1px;
}

.Dashboard_topCardRight__1Sbw0 {
  float: right;
  padding: 15px 0;
  padding-bottom: 0px;
}

.Dashboard_topCardRight__1Sbw0 img {
  width: 58px;
}

.Dashboard_assignmentDiv__wjvpz h5,
.Dashboard_queriesDiv__3sq3k h5 {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-bottom: 5px;
}

.Dashboard_assignmentDiv__wjvpz p {
  display: inline-block;
  font-size: 14px;
  color: #686f7a;
  margin-bottom: 0;
  margin-top: 3px;
}

.Dashboard_queriesDiv__3sq3k p {
  display: inline-block;
  font-size: 14px;
  color: #686f7a;
  margin-bottom: 0;
  line-height: 27px;
}

.Dashboard_hr__3WkKW {
  margin-top: 10px;
  margin-bottom: 10px;
}

.Dashboard_curriculum__23Z5f {
  margin-right: 10px;
  font-size: 15px;
}

.Dashboard_subject__YBrwr,
.Dashboard_chapter__wJATE {
  float: left;
  margin-right: 10px;
  font-size: 15px;
}

.Dashboard_queryDiv__1ZzLq {
  line-height: 28px;
}

.Dashboard_queryDiv__1ZzLq .Dashboard_subject__YBrwr {
  float: none;
  margin-right: 10px;
  font-size: 14px;
  width: 100%;
}

.Dashboard_queryDiv__1ZzLq .Dashboard_topic__3tmu1 {
  float: none;
  margin-right: 10px;
  font-size: 14px;
}

.Dashboard_queryDiv__1ZzLq .Dashboard_chapter__wJATE {
  margin-right: 10px;
  font-size: 14px;
}

.Dashboard_sessionsDiv__1CzTH h2 {
  font-size: 15px;
  margin-bottom: 5px;
  margin-top: 0;
  color: #333;
}

.Dashboard_sessionsDiv__1CzTH p {
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 0;
}

.Dashboard_sessionsDiv__1CzTH span {
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 10px;
  color: #fff;
  letter-spacing: 1px;
}

.Dashboard_sessionsDiv__1CzTH button {
  margin-top: 12px;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.16), 0 0px 0px 0 rgba(0, 0, 0, 0.12);
  background-color: #ea9f07;
  border-color: #ea9f07;
  width: 100%;
  padding: 5px;
  border-radius: 3px;
}

.share_cardDiv__2qF0K {
  background: rgb(255, 255, 255);
  width: 100%;
  border-radius: 6px;
  border: 2px solid rgb(238, 238, 238);
  padding: 15px;
  padding-top: 30px;
  text-align: center;
  /* align-items: center;
  justify-content: center;
  display: flex;
  font-size: 15px; */
}

.share_center__1p7Qu {
  font-weight: 400;
  margin-top: 3px;
}

.share_modalContent__3eFTM {
  background: transparent !important;
  border: 0px !important;
}

.share_modalBackdrop__1TqFU {
  opacity: 0.7 !important;
}

.MyQueries_queryDiv__2LtJd p {
  display: inline-block;
  font-size: 14px;
  color: #686f7a;
  margin-bottom: 0;
  line-height: 27px;
}

.MyQueries_dashimg__2fEme {
  height: 60vh;
  overflow: hidden;
}

.MyQueries_hr__1hG29 {
  margin-top: 15px;
  margin-bottom: 10px;
  clear: both;
}

.MyQueries_curriculum__2cwAt,
.MyQueries_subject__2W7q5,
.MyQueries_topic__301XQ,
.MyQueries_chapter__1u2Bs {
  float: left;
  margin-right: 10px;
  margin-top: 8px;
  font-size: 16px;
  /* line-height: 20px; */
}

.MyQueries_main__YcBMn {
  width: 94vw;
  border-radius: 6px;
  /* border: 1px solid black; */
  margin-top: 25px;




}

.MyQueries_queryFeatures__1hl6t {
  /* background: #e9edf6; */
  background: #e9eaec;
  margin-top: 10px;
  padding: 10px;
  overflow: hidden;
}

.MyQueries_queryFeatures__1hl6t span {
  cursor: pointer;
  padding: 4px 10px;
  margin-left: 10px;
  border-radius: 2px;
  float: right;
  color: #fff;
  font-size: 13px;
  letter-spacing: 0.5px;
}

.MyQueries_queryFeatures__1hl6t .MyQueries_replay__12xpT {
  background: #ffff;
}

.MyQueries_queryFeatures__1hl6t .MyQueries_resolve__3PFyH {
  background: #66bb6a;
}

.MyQueries_queryFeatures__1hl6t .MyQueries_feedback__FSeTk {
  background: #42a5f5;
}

.MyQueries_raiseQueryDiv__d60en select {
  height: 42px;
}

.MyQueries_noReplay__roZ3e {
  color: #000 !important;
  font-size: 14px !important;
  font-weight: 500;
}

.MyQueries_studentReview__2sZ5J {
  background: #e6f3fd;
  padding: 10px;
  border-radius: 5px;
  width: 70%;
  margin: 10px 0px;
}

.MyQueries_teacherReview__3IJnC {
  background: #fff2e8;
  padding: 10px;
  border-radius: 5px;
  width: 70%;
  margin: 10px 0px;
}

.MyQueries_messagesDiv__1uV2Q {
  max-height: 250px;
  overflow-y: auto;
}

/* width */
.MyQueries_queryDiv__2LtJd ::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.MyQueries_queryDiv__2LtJd ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px #ccc;
  border-radius: 10px;
}

/* Handle */
.MyQueries_queryDiv__2LtJd ::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 10px;
}

/* Handle on hover */
.MyQueries_queryDiv__2LtJd ::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}

.MyQueries_open__3zuAW {
  display: block;
}

.MyQueries_close__z1jpu {
  display: none;
}

.MyQueries_cardDiv2__G2rbC {
  background-color: #ecf3ff;
  Width: 1170px;
  Height: 244px;
  Top: 437px;
  Left: 67px;
  Radius: 6px;
  margin: 15px;
  border-radius: 6px;
}

.MyQueries_cardDiv3__yAqH4 {
  background-color: #ECF3FF;
  height: 190px;
  border-radius: 6px;
}








@media screen and (min-width: 280px) {
  .MyQueries_cardDiv3__yAqH4 {
    background-color: #ECF3FF;
    height: 190px;
    border-radius: 6px;
    width: 19%;
  }
}
@media screen and (min-width: 280px) {

  .MyQueries_curriculum__2cwAt,
  .MyQueries_subject__2W7q5,
  .MyQueries_topic__301XQ,
  .MyQueries_chapter__1u2Bs {
    float: left;
    margin-right: 3px;
    margin-top: 8px;
    font-size: 9px;
    /* line-height: 20px; */
  }
}


@media screen and (min-width: 360px) {
  .MyQueries_cardDiv3__yAqH4 {
    background-color: #ECF3FF;
    height: 190px;
    border-radius: 6px;
    width: 25%;
  }
}

@media screen and (min-width: 360px) {

  .MyQueries_curriculum__2cwAt,
  .MyQueries_subject__2W7q5,
  .MyQueries_topic__301XQ,
  .MyQueries_chapter__1u2Bs {
    float: left;
    margin-right: 1px;
    margin-top: 8px;
    font-size: 12px;
    /* line-height: 20px; */
  }
}

@media screen and (min-width: 375px) {
  .MyQueries_cardDiv3__yAqH4 {
    background-color: #ECF3FF;
    height: 190px;
    border-radius: 6px;
    width: 26%;
  }
}

@media screen and (min-width: 375px) {

  .MyQueries_curriculum__2cwAt,
  .MyQueries_subject__2W7q5,
  .MyQueries_topic__301XQ,
  .MyQueries_chapter__1u2Bs {
    float: left;
    margin-right: 1px;
    margin-top: 8px;
    font-size: 13px;
    /* line-height: 20px; */
  }
}
@media screen and (min-width: 412px) {
  .MyQueries_cardDiv3__yAqH4 {
    background-color: #ECF3FF;
    height: 190px;
    border-radius: 6px;
    width: 29%;
  }
}
@media screen and (min-width: 412px) {

  .MyQueries_curriculum__2cwAt,
  .MyQueries_subject__2W7q5,
  .MyQueries_topic__301XQ,
  .MyQueries_chapter__1u2Bs {
    float: left;
    margin-right: -1px;
    margin-top: 8px;
    font-size: 14px;
    /* line-height: 20px; */
  }
} 
@media screen and (min-width: 540px) {
  .MyQueries_cardDiv3__yAqH4 {
    background-color: #ECF3FF;
    height: 190px;
    border-radius: 6px;
    width: 40%;
  }
}
@media screen and (min-width: 768px) {
  .MyQueries_cardDiv3__yAqH4 {
    background-color: #ECF3FF;
    height: 190px;
    border-radius: 6px;
    width: 58%;
  }
}

@media screen and (min-width: 820px) {
  .MyQueries_cardDiv3__yAqH4 {
    background-color: #ECF3FF;
    height: 190px;
    border-radius: 6px;
    width: 62%;
  }
}

@media screen and (min-width: 1024px) {
  .MyQueries_cardDiv3__yAqH4 {
    background-color: #ECF3FF;
    height: 190px;
    border-radius: 6px;
    width: 79%;
  }
}

@media screen and (min-width: 912px) {
  .MyQueries_cardDiv3__yAqH4 {
    background-color: #ECF3FF;
    height: 190px;
    border-radius: 6px;
    width: 70%;
  }
}

@media screen and (min-width: 1280px) {
  .MyQueries_cardDiv3__yAqH4 {
    background-color: #ECF3FF;
    height: 190px;
    border-radius: 6px;
    width: 100%;
  }
}
.Assignments_assignmentDiv__1kjGr h5 {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-bottom: 5px;
  cursor: pointer;
}
/* .theam-color{
  background: #222648 !important;
} */
.Assignments_dashimg__3u3Nd {
  height: 60vh;
  overflow: hidden;
}

.Assignments_assignmentDiv__1kjGr p {
  display: inline-block;
  font-size: 14px;
  color: #686f7a;
  margin-bottom: 0;
  margin-top: 3px;
}

.Assignments_assignment-div__3-1dm span {
  color: #fff;
  width: 100%;
  display: block;
  padding: 1px;
  margin-top: 5px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.Assignments_assignment-div__3-1dm span.Assignments_success__1k4EF {
  background: green;
}

.Assignments_assignment-div__3-1dm span.Assignments_fail__38LCQ {
  background: red;
}

.Assignments_test-summary__2dCPh .Assignments_badge__RQWtt {
  float: right;
}

.Assignments_testSummary__1IpMc {
  background: #fff;
  width: 100%;
  float: left;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #efefef;
  box-shadow: none;
  transition: all 0.2s ease-in-out;
}

.Assignments_testSummary__1IpMc p {
  display: block;
  padding: 12px 10px;
  text-align: left;
  border-bottom: 1px dashed #efefef;
  margin: 0px;
}

.Assignments_testSummary__1IpMc p:last-child {
  padding-bottom: 5px;
  border-bottom: 0;
}

.Assignments_testSummary__1IpMc .Assignments_badge__RQWtt {
  float: right;
  padding: 6px 8px;
  font-weight: 400;
  font-size: 13px;
  width: 30px;
}

.Assignments_assignmentResult__254gt {
  display: block;
  background: transparent;
  margin-top: 8px;
  padding: 3px 10px;
  color: #fff;
  border-radius: 3px;
  text-align: center;
}

.Assignments_assignmentPass__PCkK6 {
  background: #4caf50;
}

.Assignments_assignmentPassAvg__12AvE {
  background: #ffbf00;
}

.Assignments_assignmentFail__1sxlG {
  background: #f71b1b;
}

.Assignments_assignmentPreviewDiv__Ys-S5 {
  background: #fff;
  float: left;
  width: 100%;
  border-radius: 6px;
  border: 2px solid #eee;
  padding: 15px;
  box-shadow: 0 0 11px -6px rgb(187 187 187 / 65%);
  font-size: 14px;
  overflow: hidden;
}

.Assignments_assignmentPreviewDiv__Ys-S5 table th {
  border: 0px;
}

.Assignments_assignmentPreviewDiv__Ys-S5 table td {
  border: 0px;
}

.Assignments_assignmentPreviewDiv__Ys-S5 h2 {
  font-size: 22px;
  margin-bottom: 17px;
  font-weight: 600;
  color: #333;
  text-align: center;
}

.Assignments_testBack__14A0s {
  position: absolute;
  right: 0px;
}

.Assignments_testStart__1EpC3 {
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.Assignments_testTimerBg__3g0xB {
  background: #fff;
  width: 100%;
  float: left;
  padding: 0;
  border-radius: 10px;
  margin-bottom: 50px;
  border: 1px solid #efefef;
  transition: all 0.2s ease-in-out;
}

.Assignments_testTimerLeft__3CIb6 {
  width: 100%;
  float: left;
  display: flex;
  margin-bottom: 0px;
}

.Assignments_testTimerLeft__3CIb6 li {
  width: 50%;
  display: inline-block;
  padding: 30px 20px;
  border-right: 1px solid #efefef;
  text-align: center;
}

.Assignments_testTimerLeft__3CIb6 h4 {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin-bottom: 15px;
  text-align: center;
  line-height: 26px;
  height: 100px;
  width: 100px;
  display: inline-block;
  border: 2px solid #333;
  border-radius: 100%;
  padding: 35px 0;
  background: #fff;
}

.Assignments_quesTitle__sPyti {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  text-align: left;
  line-height: 28px;
  margin-top: 0;
  margin-bottom: 14px;
}

.Assignments_quesTitle__sPyti span {
  font-weight: 600;
  margin-right: 10px;
  float: left;
}

.Assignments_testInfo__1fqyw {
  text-align: right;
  padding: 8px;
  box-shadow: 0 0.052083333in 11.25pt -9px rgb(0 0 0 / 10%);
  border-bottom: 1px solid #e6e6e6;
}

.Assignments_testInfo__1fqyw h3 {
  font-size: 18px;
  font-weight: 500;
  color: #333;
  margin-top: 5px;
  margin-bottom: 5px;
}

.Assignments_radioCheckbox__oMrU2 input[type="radio"] {
  margin-right: 7px;
  position: relative;
  top: 1px;
}

.Assignments_quesItem__XmMUe {
  margin-bottom: 30px;
  box-shadow: 0px 1px 13px 0px rgb(198 198 198 / 30%);
  border: 1px solid rgba(51, 122, 183, 0.21);
  padding: 10px;
  background: #fff;
}

.Assignments_backLink__1LifM {
  color: #333;
  float: right;
  height: 38px;
  background: #f1f1f1;
  border: 1px solid #d0d0d0;
  border-radius: 20px;
  padding: 8px 20px;
  font-size: 14px;
  margin-top: 10px;
}

.Assignments_backLink__1LifM a {
  text-decoration: none;
  color: #000;
}

.Assignments_testResultLeft__rdaWj {
  text-align: center;
}

.Assignments_testResultLeft__rdaWj li {
  width: 150px;
  display: inline-block;
  padding: 0;
}

.Assignments_resultDt__2_ebN {
  float: left;
  width: 100%;
  text-align: center;
}

.Assignments_resultDt__2_ebN span {
  font-size: 40px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 10px;
  text-align: center;
  line-height: 75px;
  height: 75px;
  width: 75px;
  display: inline-block;
  border-radius: 100%;
}

.Assignments_resultDt__2_ebN p {
  font-weight: 500;
  font-size: 16px;
}

.Assignments_rightAns__1-xdd {
  background: #01b200;
}

.Assignments_wrongAns__2JWaA {
  background: #fb3838;
}

.Assignments_totQues__2CCXL {
  background: #57a4ff;
}
.Assignments_my-component__2QGgm {
  background: #fff;
}

.Assignments_fullscreen-enabled__2JcuJ .Assignments_my-component__2QGgm {
  background: #000;
}
.Curriculum_progress__2K-ZP {
  height: 10px;
  border: 1px solid #f1f1f1;
  background: #e0e0e0;
  border-radius: 10px;
}
.Curriculum_dashimg__1NzDN {
  height: 60vh;
  overflow: hidden;
}

.Curriculum_progressBar__3gVfy {
  height: 8px;
  border-radius: 10px;
}

.Curriculum_chaptersDiv__3Gjex table {
  background: #fff;
}

.Curriculum_chaptersDiv__3Gjex table td:nth-child(2) {
  font-weight: 500;
  width: 200px;
}

.Curriculum_chaptersDiv__3Gjex table td:nth-child(3) {
  width: 40%;
}

.Curriculum_modalBody__GhBIX {
  min-height: 400px;
}

.Curriculum_notAvailable__23cwN {
  text-align: center;
  width: 100%;
  padding-top: 150px;
  font-size: 18px;
  font-weight: 500;
}
.Curriculum_txt__3EJJg {
  display: flex;
  /* position: absolute; */
  width: 100%;
  height: 244px;
  left: 63px;
  top: 437px;

  background: #ecf3ff;
  border-radius: 6px;
}

.Curriculum_box__3HgbE {
  /* box-sizing: border-box; */

  /* position: absolute; */
  width: auto;
  height: auto;
  left: 34px;
  top: 341px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
  margin-bottom: 20px;

  border: 1px solid #000000;
  border-radius: 6px;
}

@media screen and (min-width: 1200px) {
  .Curriculum_txt__3EJJg {
    width: 100%;
  }
}

@media screen and (min-width: 1100) {
  .Curriculum_txt__3EJJg {
    width: 900px;
  }
}
@media screen and (max-width: 750) {
  .Curriculum_txt__3EJJg {
    width: 200px;
  }
}

.HelpFromTutor_tutorDiv__2BidA p {
  display: inline-block;
  font-size: 14px;
  color: #686f7a;
  margin-bottom: 0;
  line-height: 27px;
}

.HelpFromTutor_dashimg__upA6k {
  height: 60vh;
  overflow: hidden;
}

.HelpFromTutor_hr__2UJ0Z {
  margin-top: 15px;
  margin-bottom: 10px;
  clear: both;
}

.HelpFromTutor_curriculum__QEUr6,
.HelpFromTutor_subject__3TOVm,
.HelpFromTutor_topic__2tJ3d,
.HelpFromTutor_chapter__3zNB4 {
  float: left;
  margin-right: 10px;
  margin-top: 8px;
  font-size: 16px;
  line-height: 20px;
}

.HelpFromTutor_tutorFeatures__3wNob {
  /* background: #e9edf6; */
  background: #e9eaec;
  margin-top: 10px;
  padding: 10px;
  overflow: hidden;
}

.HelpFromTutor_tutorFeatures__3wNob span {
  cursor: pointer;
  padding: 4px 10px;
  margin-left: 10px;
  border-radius: 2px;
  float: right;
  color: #fff;
  font-size: 13px;
  letter-spacing: 0.5px;
}

.HelpFromTutor_tutorFeatures__3wNob .HelpFromTutor_replay__bBvND {
  background: #7986cb;
}

.HelpFromTutor_tutorFeatures__3wNob .HelpFromTutor_resolve__1Ye6g {
  background: #66bb6a;
}

.HelpFromTutor_tutorFeatures__3wNob .HelpFromTutor_feedback__1dg9n {
  background: #42a5f5;
}

.HelpFromTutor_raiseQueryDiv__eCUK8 select {
  height: 42px;
}

.HelpFromTutor_status__1VyGy {
  font-weight: 500;
}

.HelpFromTutor_scheduledDate__2-SQ6 {
  margin-left: 20px;
  font-weight: 500;
}

.HelpFromTutor_open__NIcX6 {
  display: block;
}

.HelpFromTutor_close__2PS0B {
  display: none;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
  height: 38px;
}
#combo-box-demo {
  position: absolute;
  width: 100%;
}

.Lab_LabDiv__2VM5W h5,
.Lab_queriesDiv__gSylZ h5 {
  font-size: 15px;
  font-weight: 500;
  color: #000000;
}

.Lab_LabDiv__2VM5W p {
  display: inline-block;
  font-size: 14px;
  color: #686f7a;
  margin-bottom: 0;
  margin-top: 3px;
}

.Lab_LabSummary__2gLjS {
  background: #fff;
  width: 100%;
  float: left;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #efefef;
  box-shadow: none;
  transition: all 0.2s ease-in-out;
}

.Lab_LabSummary__2gLjS p {
  display: block;
  padding: 12px 10px;
  text-align: left;
  border-bottom: 1px dashed #efefef;
  margin: 0px;
}

.Lab_LabSummary__2gLjS p:last-child {
  padding-bottom: 5px;
  border-bottom: 0;
}

.Lab_LabSummary__2gLjS .Lab_badge__12iCA {
  float: right;
  padding: 6px 8px;
  font-weight: 400;
  font-size: 13px;
  width: 30px;
}

.Lab_testType__otcJd {
  width: 110px;
  float: left;
  background: #fff;
  color: #0077cd;
  padding: 7px 10px;
  margin-right: 10px;
  border-radius: 3px;
  font-weight: 500;
  cursor: pointer;
}

.Lab_active__1nXUx {
  background: #0077cd;
  color: #fff;
}

.Lab_LabResult__2K_BK {
  display: block;
  background: transparent;
  margin-top: 8px;
  padding: 3px 10px;
  color: #fff;
  border-radius: 3px;
  text-align: center;
}

.Lab_labPass__3P0Fh {
  background: #4caf50;
}

.Lab_labPassAvg__1FOKn {
  background: #ffbf00;
}

.Lab_labFail__2MKdJ {
  background: #f71b1b;
}

.Sessions_sessionsDiv__2KdYR h2 {
  font-size: 15px;
  margin-bottom: 5px;
  margin-top: 0;
  color: #333;
}
.Sessions_dashimg__1ScM6 {
  height: 60vh;
  overflow: hidden;
}
.Sessions_sessionsDiv__2KdYR p {
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 0;
}

.Sessions_sessionsDiv__2KdYR span {
  font-size: 12px;

  padding: 5px 10px;
  border-radius: 10px;
  color: #fff;
  letter-spacing: 1px;
}

.Sessions_sessionsDiv__2KdYR button {
  margin-top: 12px;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.16), 0 0px 0px 0 rgba(0, 0, 0, 0.12);
  background-color: #ea9f07;
  border-color: #ea9f07;
  width: 100%;
  padding: 5px;
  border-radius: 3px;
}

.Sessions_sessionPreviewDiv__1qKNy {
  background: #fff;
  margin-bottom: 20px;
  float: left;
  width: 100%;
  border-radius: 6px;
  border: 2px solid #eee;
  padding: 15px;
  box-shadow: 0 0 11px -6px rgb(187 187 187 / 65%);
  font-size: 14px;
}

.Sessions_sessionPreviewDiv__1qKNy table th {
  border: 0px;
}

.Sessions_sessionPreviewDiv__1qKNy table td {
  border: 0px;
}

.Sessions_sessionPreviewDiv__1qKNy h2 {
  font-size: 22px;
  margin-bottom: 17px;
  font-weight: 600;
  color: #333;
  text-align: center;
}

.Sessions_testBack__2r1fY {
  position: absolute;
  right: 10px;
}

.Sessions_testStart__2SSz7 {
  position: absolute;
  right: 10px;
  bottom: 20px;
}

.calender_dashimg__1Xm2d {
  height: 60vh;
  overflow: hidden;
}
.calender_timeWidth__ws_9c {
  width: 150px !important;
}
.calender_react-datepicker__time-container__10C5G {
  width: 150px !important;
}

.sessionForm .label-heading {
  width: 100px;
  float: left;
  position: relative;
  top: 8px;
}

.sessionForm .form-control,
.sessionForm .react-datepicker-wrapper {
  font-size: 15px !important;
  width: 70% !important;
}

.sessionForm .react-datepicker-wrapper input {
  width: 100% !important;
}

.sessionForm .error {
  margin-left: 100px;
}


.pricing-table{
  background-color: #eee;
  font-family: 'Montserrat', sans-serif;
}

.pricing-table .block-heading {
  padding-top: 50px;
  margin-bottom: 40px;
  text-align: center; 
}

.pricing-table .block-heading h2 {
  color: #3b99e0;
}

.pricing-table .block-heading p {
  text-align: center;
  max-width: 420px;
  margin: auto;
  opacity: 0.7; 
}

.pricing-table .heading {
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); 
}

.pricing-table .item {
  background-color: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.075);
  border-top: 2px solid #5ea4f3;
  padding: 30px;
  overflow: hidden;
  position: relative; 
}

.pricing-table .col-md-5:not(:last-child) .item {
  margin-bottom: 30px; 
}

.pricing-table .item button {
  font-weight: 600; 
}

.pricing-table .ribbon {
  width: 160px;
  height: 32px;
  font-size: 12px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  box-shadow: 0px 2px 3px rgba(136, 136, 136, 0.25);
  background: #4dbe3b;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  position: absolute;
  right: -42px;
  top: 20px;
  padding-top: 7px; 
}

.pricing-table .item p {
  text-align: center;
  margin-top: 20px;
  opacity: 0.7; 
}

.pricing-table .features .feature {
  font-weight: 600; }

.pricing-table .features h4 {
  text-align: center;
  font-size: 18px;
  padding: 5px; 
}

.pricing-table .price h4 {
  margin: 15px 0;
  font-size: 45px;
  text-align: center;
  color: #2288f9; 
}

.pricing-table .buy-now button {
  text-align: center;
  margin: auto;
  font-weight: 600;
  padding: 9px 0; 
}
.Topbar_navbar__1ecma {
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 60px;
  background: #fff;
  display: flex !important;
  align-items: center;
  box-shadow: 0 0.052083333in 11.25pt -9px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #e6e6e6;
}

.Topbar_dropdownMenu__3DmMQ {
  right: 0px !important;
  left: auto !important;
}

.Topbar_navbarNav__daIX2 i {
  font-size: 25px;
}
.Topbar_map__1BF1B {
  height: 10px;
}
.Topbar_flat__O7zUl{
  background-color:red
}

.cardDiv {
  background: #fff;
  margin-bottom: 20px;
  float: left;
  width: 100%;
  border-radius: 6px;
  border: 2px solid #eee;
  padding: 15px;
}
.cardDivtop {
  /* background-color: rgb(255, 136, 0); */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: space-around;
  /* margin-top: 80px;
  margin-left: 40px; */
}
.home {
  /* background-color: aqua; */
  display: flex;
}
.row1 {
  display: flex;
  width: 100%;
  justify-content: space-around;
}
.cardDivAss {
  background: #fff;
  margin-bottom: 20px;
  float: left;
  width: 209px;
  border-radius: 29px;
  border: 2px solid #eee;
  padding: 15px;
  height: 275px;
  background-color: #ffa04d;
}

.cardDivSess {
  background: #fff;
  margin-bottom: 20px;
  float: left;
  width: 209px;
  border-radius: 29px;
  border: 2px solid #eee;
  padding: 15px;
  height: 275px;
  background: #0747a6;
}

.cardDivMy {
  background: #fff;
  margin-bottom: 20px;
  float: left;
  width: 188px;
  height: 153px;
  border-radius: 29px;
  border: 2px solid #eee;
  padding: 15px;
  background: #fdda5f;
}
/* h4::before {
  content: "---";
  background-color: #c182f9;
  color: #c182f9;
  font-weight: bold;
  border-radius: 100%;
} */

.cardDiv1 {
  /* background-color: #ECF3FF;; */
  width: 1170px;
  height: 244px;
  top: 437px;
  left: 67px;
  radius: 6px;
  margin: 15px;
  border-radius: 6px;
}

.header_navbar__NqUjg {
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 60px;
  background: #fff;
  display: flex !important;
  align-items: center;
  box-shadow: 0 0.052083333in 11.25pt -9px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #e6e6e6;
}

.header_dropdownMenu__1s-JQ {
  right: 0px !important;
  left: auto !important;
}

.header_navbarNav__mY11I i {
  font-size: 25px;
}

.nav-link.active {
  color: #222 !important;
  background-color: #fff !important;
}
.sidebar_navbarHeader__JrG_X {
  padding: 3px 5px;
  background: #222648;
}

.sidebar_navbarBrand__2T4-D {
  font-size: 22px !important;
  font-weight: 600 !important;
  letter-spacing: 1px !important;
}

.sidebar_mainSidebar__34hH7 {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 240px;
  z-index: 9999;
  transition: width 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  background: #222648;
  box-shadow: rgba(146, 153, 184, 0.063) 0px 0px 30px;
  overflow-y: auto;
}

.sidebar_mainSidebar__34hH7 ul {
  padding-left: 15px;
  position: absolute;
  width: 100%;
  height: 100%;
  padding-left: 0px;
}

.sidebar_mainSidebar__34hH7 li {
  padding: 2px 0px;
  cursor: pointer;
}

.sidebar_mainSidebar__34hH7 a {
  color: #fff;
  font-size: 14px;
  text-decoration: none;
  font-weight: 400;
  padding: 10px 15px !important;
}

.sidebar_sidebaricons__2CiVx {
  color: #dadada;
  margin-right: 10px;
  font-size: 20px;
  margin-right: 6px;
  position: relative;
  top: 3px;
  width: 30px;
}
.sidebar_nav-link__2XwrL.sidebar_active__KvtBD {
  color: #333 !important;
  background-color: #fff !important;
}

.logo {
  height: 200px;
  width: 200px;
  border: 3px dotted #000;
  padding: 5px;
}
.imageLogo{
    height: 200px;
    width: 200px;

}

#combo-box-demo {
  position: absolute;
  width: 81%;
  overflow: hidden;
}

.teacherSession_date__1fq8W {
  background-color: #222648;
  border-radius: 50px;
  padding: 3px 20px;
  color: rgb(255, 255, 255);
}

.teacherSession_head__WHG5A {
  font-size: 18px;
}
.teacherSession_num__2SQEP {
  font-size: 23px;
  text-align: center;
}
.teacherSession_click__3pwO8 {
  height: 30px;
  margin: 3px;
 padding: 50px;
 
}

.adminSession_date__3fPx_ {
  background-color: rgb(0, 121, 210);
  border-radius: 50px;
  padding: 3px 20px;
  color: rgb(255, 255, 255);
}

.adminSession_head__nwHeN {
  font-size: 18px;
}
.adminSession_num__1u2EC {
  font-size: 23px;
  text-align: center;
}
.adminSession_click__3ItT0 {
  height: 30px;
  margin: 3px;
  padding: 50px;
}
.adminSession_acceptedTecher__1myib{
  max-height: 150px;
  overflow: scroll;
}

.adminSession_teacherLi__1aRzX{
    display: flex;
    flex-direction: row;
    grid-gap: 30px;
    gap: 30px;
    background-color: red;
}
.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4D4D4D;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: #19AB27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #128D15;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4D4D4D;
  border-radius: 50%;
  background-color: #FAFAFA;
  box-sizing: border-box;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #19AB27;
}

.react-toggle--focus .react-toggle-thumb {
  box-shadow: 0px 0px 2px 3px #0099E0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  box-shadow: 0px 0px 5px 5px #0099E0;
}

.record_progress__1-Zx_ {
  height: 10px;
  border: 1px solid #f1f1f1;
  background: #e0e0e0;
  border-radius: 10px;
}

.record_progressBar__3T5Y3 {
  height: 8px;
  border-radius: 10px;
}

.record_chaptersDiv__pD8-e table {
  background: #fff;
}

.record_chaptersDiv__pD8-e table td:nth-child(2) {
  font-weight: 500;
  width: 200px;
}

.record_chaptersDiv__pD8-e table td:nth-child(3) {
  width: 40%;
}

.record_modalBody__tMfXl {
  min-height: 400px;
}

.record_notAvailable__2z5kI {
  text-align: center;
  width: 100%;
  padding-top: 150px;
  font-size: 18px;
  font-weight: 500;
}

.form-heading {
  font-size: 23px;
  text-align: center;
  margin-bottom: 15px;
  margin-top: 15px;
  font-weight: 600;
  width: 100%;
}

.ck-content {
  min-height: 150px;
  height: 150px !important;
  overflow-y: auto !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.add-btn-div {
  width: 100%;
  padding: 15px 0;
  margin-top: -60px;
  text-align: end;
}

.add-btn {
  float: right;
}
.add-btn-1{
  float: left; 
}

.text-view {
  width: 100%;
  border: 1px solid #efecec;
  min-height: 50px;
  vertical-align: middle;
  border-bottom: 0px;
  padding: 12px;
  display: flex;
}

.text-view:last-child {
  border-bottom: 1px solid #efecec;
}

.text-view label {
  font-weight: 500;
  color: #908e8e;
  display: block;
  margin: 2px;
  font-size: 14px;
  float: left;
  width: 35%;
}

.text-view span {
  font-weight: 400;
  font-size: 15px;
  width: 65%;
}

.text-view-long span {
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
}

.closePage {
  clear: both;
  width: 100%;
  background: #ffffff;
  padding: 10px 15px;
  margin-bottom: 20px;
  border-bottom: 1px solid #dcdcdc;
  margin-top: -18px;
}

.closePage h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: 5px;
  float: left;
}

.closePage .btn {
  float: right;
  margin-top: 2px;
  width: 100px;
  letter-spacing: 1px;
  font-weight: 500;
}

.table {
  margin-bottom: 0px;
}

.table > thead > tr {
  background-color: #ececec !important;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 0px;
}

.table td {
  vertical-align: middle !important;
}

.table td:last-child {
  width: 150px;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 0px solid #dee2e6;
  font-weight: 600;
  font-size: 15px;
}

.question-list {
  padding: 20px;
  line-height: 30px;
}

.la-check-circle {
  color: green;
  font-size: 20px;
  position: relative;
  top: 2px;
}

i.las.la-times-circle {
  color: red;
  font-size: 20px;
  position: relative;
  top: 2px;
}
.question-list .ans {
  margin-bottom: 5px;
}

.form-control {
  font-size: 20px !important;
}

.staffSelection {
  position: relative;
  top: 40px;
  left: 15px;
}

.eventModal .text-view label {
  width: 170px;
  color: #000;
}

.eventModal .text-view {
  width: 100%;
  border: 0px solid #efecec;
  min-height: 30px;
  vertical-align: middle;
  border-bottom: 0px;
  padding: 7px;
}

.eventModal .text-view span {
  font-weight: 400;
  font-size: 14px;
}

.eventModal .text-view ol {
  margin-left: 150px;
  margin-bottom: 0px;
}

.eventModal .modal-title {
  font-size: 18px;
}

.Sidebar_mainSidebar__1FKh3 {
  position: fixed;
  left: 0px;
  top: 0px;
  bottom: 0px;
  background: #fff;
  overflow: hidden;
  width: 240px;
  z-index: 1030;
  box-shadow: 0 0 11px -6px rgb(0 121 210 / 65%);
}

.Sidebar_logo__1s0t9 {
  text-align: center;
}

.Sidebar_logo__1s0t9 img {
  height: 100px;
  margin: 5px auto;
  display: block;
}

.Sidebar_logo__1s0t9 span {
  font-weight: 500;
  font-size: 14px;
  margin-top: 12px;
  display: block;
}

.Sidebar_mainSidebar__1FKh3 ul {
  padding-left: 15px;
  position: absolute;
  width: 100%;
  height: 100%;
  padding-left: 0px;
  top: 140px;
}

.Sidebar_mainSidebar__1FKh3 li {
  cursor: pointer;
  padding: 2px 0px;
}

.Sidebar_mainSidebar__1FKh3 .Sidebar_navLink__ZmbPE {
  font-size: 14px;
  text-decoration: none;
  color: #333;
  font-weight: 500;
  padding: 10px 15px !important;
}

.Sidebar_sideIcon__2H8zX {
  font-size: 18px;
  margin-right: 8px;
  position: relative;
  top: 2px;
  color: #a2a2a2;
}

.Topbar_navbar__2a_k8 {
  position: fixed;
  width: 100%;
  height: 60px;
  background: #fff;
  display: flex !important;
  align-items: center;
  box-shadow: 0 0.052083333in 11.25pt -9px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #e6e6e6;
}

.Topbar_dropdownMenu__1cvFl {
  right: 0px !important;
  left: auto !important;
}

.Topbar_navbarNav__1KbTr i {
  font-size: 25px;
}

.Sidebar_mainSidebar__wfJqz {
  position: fixed;
  left: 0px;
  top: 0px;
  bottom: 0px;
  background: #fff;
  overflow: hidden;
  width: 240px;
  z-index: 1030;
  box-shadow: 0 0 11px -6px rgb(0 121 210 / 65%);
}

.Sidebar_logo__3htur {
  text-align: center;
}

.Sidebar_logo__3htur img {
  height: 100px;
  margin: 5px auto;
  display: block;
}

.Sidebar_logo__3htur span {
  font-weight: 500;
  font-size: 14px;
  margin-top: 12px;
  display: block;
}

.Sidebar_mainSidebar__wfJqz ul {
  padding-left: 15px;
  position: absolute;
  width: 100%;
  height: 100%;
  padding-left: 0px;
  top: 140px;
  background-color: #222648;
}

.Sidebar_mainSidebar__wfJqz li {
  cursor: pointer;
  padding: 2px 0px;
}

.Sidebar_mainSidebar__wfJqz .Sidebar_navLink__2QU6Z {
  font-size: 14px;
  text-decoration: none;
  color: white;
  font-weight: 500;
  padding: 10px 15px !important;
}

.Sidebar_sideIcon__3_6cq {
  font-size: 18px;
  margin-right: 8px;
  position: relative;
  top: 2px;
  color: #a2a2a2;
}

.Topbar_navbar__1zNA- {
  position: fixed;
  width: 100%;
  height: 60px;
  background: #fff;
  display: flex !important;
  align-items: center;
  box-shadow: 0 0.052083333in 11.25pt -9px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #e6e6e6;
}

.Topbar_dropdownMenu__q-ve2 {
  right: 0px !important;
  left: auto !important;
}

.Topbar_navbarNav__2yJrj i {
  font-size: 25px;
}

.theam-color {
  background: #222648 !important;
}

.register {
  background: #fff;
  /* background: -webkit-gradient(
     linear,
     left bottom,
     right top,
     from(#fc2c77),
     to(#6c4079)
   );
   background: -webkit-linear-gradient(bottom left, #fc2c77 0%, #6c4079 100%);
   background: -moz-linear-gradient(bottom left, #fc2c77 0%, #6c4079 100%);
   background: -o-linear-gradient(bottom left, #fc2c77 0%, #6c4079 100%);
   background: linear-gradient(to top right, #fc2c77 0%, #6c4079 100%); */
  /* position: absolute; */
  right: 0px;
  left: 0px;
  top: 0px;
  bottom: 0px;
  height: 100%;
}

.register-left {
  text-align: center;
  color: #fff;
}

.register-form .label-heading,
.login-right .label-heading {
  display: none;
}

.register-left input {
  border: none;
  border-radius: 1.5rem;
  background: #f8f9fa;
  font-weight: bold;
  color: #383d41;
  cursor: pointer;
}

.register-right {
  background: #f8f9fa;
  border-top-left-radius: 10% 50%;
  border-bottom-left-radius: 10% 50%;
  margin-top: 15px;
}

.register .register-form {
  padding: 50px 100px;
}

.btnRegister {
  border: none;
  border-radius: 1.5rem;
  padding: 8px;
  background: #0f5ef6;
  color: #fff;
  font-weight: 500;
  width: 130px;
  letter-spacing: 1px;
  font-size: 15px;
}

.register-heading {
  text-align: center;
  color: #495057;
  padding-top: 25px;
  font-weight: 600;
}

.register-form hr {
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 0px solid rgb(49 69 186 / 8%);
}

.section-row {
  border: 1px solid #ececec;
  padding: 25px 10px;
  padding-bottom: 0px;
  background: #fff;
}

.login {
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(to top right, #0079d2 0%, #0079d2 100%);
}

.login-right {
  background: #fff;
  position: absolute !important;
  height: 100%;
  padding: 0px;
  right: 0px;
}

.welcome-elearn {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 60px;
  font-weight: 700;
  font-size: 26px;
}

.welcome-elearn span {
  color: #0f5ef6;
  font-weight: 900;
}

.login hr {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  border: 0;
  border-top: 1px solid rgb(49 69 186 / 8%);
  width: 60%;
}

.lw {
  padding: 8px 10px;
  color: #000;
  font-size: 17px;
  width: 230px;
  margin: 0 auto;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-bottom: 15px;
  cursor: pointer;
  font-weight: 500;
}

.lw img {
  height: 20px;
  margin-right: 10px;
}

.loginError {
  color: red;
  text-align: center;
  margin-bottom: 20px;
  margin-top: -18px;
}
.remove1 {
  border-radius: 3px;
  border: 1px solid;
}
.register1 {
  color: white;
}
.register1:hover {
  color: white;
  text-decoration: none;
}

.password {
  position: relative;
}

#eye_icon {
  position: absolute;
  top: 8px;
  right: 23px;
  cursor: pointer;
}

.Place-Select {
  color: gray;
  width: 100%;
  height: 40px;
}

.verify_card__1Lxrb {
  width: 40%;
  text-align: center;
}
.verify_cardImg__CJJdw {
  height: 100;
}
.verify_image__2xCr1 {
  margin: "23px auto";

  display: "block";
}
.verify_cardContainer__2JgdQ {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100vh;
  padding-top: 8%;
}


#heading {
  color: rgb(79, 157, 202);
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.head {
  font-size: 65px;
}

#head-content {
  font-size: x-large;
  max-width: 550px;
  margin: auto;
  color: rgb(83, 83, 83);
}

.main {
  display: grid;
  grid-template-columns: auto auto auto auto;
  -ms-grid-column-align: center;
  margin-top: 3%;
}

.outer {
  width: 17vw;
}

#blocks {
  display: flex;
  margin: auto;
  height: 54vh;
  margin-top: 22%;
}

.block {
  border-top: 3px solid rgb(79, 157, 202);
  padding: 2%;
  padding-top: 12%;
  margin-top: 1%;
  height: 45.7vh;
  width: 20vw;
}

.block-heading {
  height: 16vh;
}

.info {
  margin-top: 4%;
  font-size: x-large;
  height: 10vh;
}

.amount {
  font-size: 55px;
  margin-top: 10%;
  margin-bottom: 6%;
  color: rgb(79, 157, 202);
}

#button {
  width: 100%;
  color: red;
  margin: auto;
}

@media only screen and (max-width: 1280px) {
}

@media only screen and (max-width: 1024px) {
  .block-heading {
    font-size: 28px;
  }

  .info {
    font-size: large;
  }

  .amount {
    font-size: 50px;
    margin-top: 10%;
  }
}

@media only screen and (max-width: 912px) {
  .outer {
    width: 25vw;
  }

  .main {
    display: grid;
    grid-template-columns: auto auto auto;
  }

  #blocks {
    margin-bottom: -80%;
  }

  .block {
    height: 25.7vh;
  }

  .block-heading {
    font-size: 28px;
    height: 8vh;
  }

  .info {
    height: 7vh;
  }

  .amount {
    font-size: 50px;
    margin-top: 7%;
  }
}

@media only screen and (max-width: 820px) {
  .block-heading {
    font-size: 24px;
    height: 8vh;
  }
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 540px) {
  .outer {
    width: 44vw;
  }

  .main {
    display: grid;
    grid-template-columns: auto auto;
    -ms-grid-column-align: center;
    margin-top: 3%;
  }

  #blocks {
    margin-bottom: 0%;
  }

  .block {
    height: 20.7vh;
    width: 50vw;
  }

  .block-heading {
    font-size: 28px;
    height: 8vh;
  }

  .info {
    margin-top: 20%;
    height: 12vh;
    font-size: x-large;
  }

  .amount {
    font-size: 50px;
    margin-top: 10%;
    margin-bottom: 20%;
  }
}

@media only screen and (max-width: 414px) {
}

@media only screen and (max-width: 375px) {
  #head-content {
    font-size: large;
  }

  .main {
    display: grid;
    grid-template-columns: auto;
  }

  .outer {
    width: 80vw;
  }

  #blocks {
    margin-bottom: 25%;
  }

  .block {
    width: 80vw;
  }



}

body {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.3px;
}
.btn-primary {
  background: #222648 !important; 
}
#containerform {
  margin-top: 20px;
  border: 1px solid black;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 6px;
}
.uttam {
  width: 100%;
}

.row {
  margin: 0px;
}

.pad-0 {
  padding: 0px;
}

.mb-35 {
  margin-bottom: 25px;
}

p {
  margin-bottom: 0px;
}

a {
  color: #0079d2;
}

.btn {
  color: #fff !important;

  background: #222648;
  text-transform: uppercase;
  word-wrap: break-word;
  white-space: normal;
  cursor: pointer;
  border: 0;
  border-radius: 3px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 0.6rem 1.5rem;
  font-size: 0.81rem;
  line-height: 1.5;
  /* width: 203px; */
  height: 53px;
}

.btn-right {
  text-transform: uppercase;
  margin-left: auto !important;
  margin-right: 15px;
  display: block;
  font-size: large;
}
@media screen and (min-width: 412px){
  .btn-right{
    text-transform: uppercase;
    margin-left: auto !important;
    margin-right: 110px;
    display: block;
    font-size: large;
    margin-top: 12px;
}
}
@media screen and (min-width: 375px){
  .btn-right{
    text-transform: uppercase;
    margin-left: auto !important;
    margin-right: 50px;
    display: block;
    font-size: large;
    /* margin-top: 12px; */
}
}

.btn-sm {
  padding: 0.3rem 0.85rem;
  font-size: 0.75rem;
  line-height: 1.5;
  letter-spacing: 0.4px;
}

.btn-success {
  color: #fff;
  background-color: #0e9c37;
  border-color: #0e9c37;
}
.btn-success:hover {
  color: #0e9c37 !important;
  background-color: #fff;
  border-color: #fff;
}

.btn-info {
  color: #fff;
  background-color: #222648;
  border-color: #222648;
}
.btn-danger:hover {
  color: #c82333 !important;
  background-color: #fff !important;
  border-color: #fff !important;
}
.btn-info:hover {
  color: #222648 !important;
  background-color: #fff !important;
  border-color: #fff;
}

.btn-primary {
  color: #fff;
  background-color: #0079d2;
  border-color: #0079d2;
}

.form-group {
  margin-bottom: 1.8rem;
}

.form-control {
  font-size: 15px !important;
  height: 38px;
  border-radius: 6px;
  width: 100% !important;
}

.formSelect {
  font-size: 15px !important;
  height: 38px;
  border-radius: 6px;
}

.label-heading {
  margin-bottom: 0.2rem;
  font-size: 14px;
  font-weight: 500;
  color: #545454;
}

.content-wrapper {
  transition: margin 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, margin 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, margin 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  margin-left: 240px;
  z-index: 820;
  margin-top: 60px;
  padding: 20px 5px;
  min-height: 500px;
  background: #f7f7f7;
  /* background: #f6f7fb; */
  overflow: hidden;
}

.text-heading {
  font-size: 18px;
  font-weight: 500;
  color: #333;
  margin-left: 15px;
  margin-bottom: 10px;
  /* margin-top: 20px; */
}

.viewMore {
  float: right;
  margin-left: auto !important;
  margin-right: 15px;
  margin-bottom: 0px;
  cursor: pointer;
  position: relative;
  font-weight: 500;
}

.viewMore a {
  color: #0079d2;
}

.viewMore i {
  font-size: 18px;
  position: relative;
  top: 1px;
}
#buttonItem {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 25px;
  margin-left: -22px;
}
/* .tabBotton {
  background: #fff !important;
  color: #222648 !important;
  border: 1px solid #222648 !important;
  border-radius: 3px !important;
  cursor: pointer !important;
  height: 20px !important;
} */

.tabItem {
  /* float: left; */
  /* background: #fff; */
  color: #222648;
  /* border: 1px solid #222648; */
  /* padding: 7px 10px; */
  /* margin-right: 80px; */
  /* border-radius: 3px; */
  /* font-weight: 300; */
  /* cursor: pointer; */
  height: 38px;

  float: left;
  font-size: large;
  font-weight: bold;
  color: #fff;
  border: 1px solid #222648;
  padding: 15px 17px;
  margin-right: 10px;
  border-radius: 3px;
  cursor: pointer;
  /* box-sizing: border-box; */
  /* width: 203px; */
  height: 53px;
  /* left: 34px;
  top: 198px; */
  background: #0747a6;
  /* border: 1px solid #ffffff; */
  /* border-radius: 12px; */
}

.tabItemActive {
  background: white;
  color: black;

  border-style: double;
  border-color: black;
}

.error {
  color: red;
  font-size: 13px;
  margin-bottom: -20px;
}

.nav-link.active {
  /* background-color: #e3f3ff; */
  background: rgb(0 121 210 / 16%);
  color: #222648;
}

.nav-link:hover {
  /* background-color: #e3f3ff; */
  background: rgb(0 121 210 / 16%);
  color: #888;
}

.nav-link.active i {
  color: #222648;
}

.event__ACCEPTED {
  color: #369803;
  font-weight: bold;
}

.event__REJECTED {
  color: red;
  font-weight: bold;
}

.event__PENDING {
  color: #292713;
  font-weight: bold;
}

.color-status span {
  height: 40px;
  display: inline-block;
  font-weight: bold;
  margin-right: 20px;
}

.color-status span.color-pending {
  color: #292713;
}

.color-status span.color-accepted {
  color: #369803;
}

.color-status span.color-rejected {
  color: red;
}

.rbc-event-label {
  display: none !important;
}

.rbc-event {
  padding: 4px 3px !important;
  background-color: #acd9ff !important;
  border: 1px solid #acd9ff !important;
  border-radius: 0px;
}

.rbc-day-slot .rbc-events-container {
  margin-right: 0px !important;
}

/* 
.event__ACCEPTED,
.event__REJECTED,
.event__PENDING {
  color: #fff;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  letter-spacing: 0.5px;
}

.color-status span {
  height: 40px;
  display: inline-block;
  font-weight: 600;
  margin-right: 15px;
}

.color-status span.color-pending {
  color: #03a9f4;
}

.color-status span.color-accepted {
  color: #4caf50;
}

.color-status span.color-rejected {
  color: #f44336;
}

.rbc-event-label {
  display: none !important;
}

.rbc-event {
  padding: 4px 3px !important;
} */

.exists-acc {
  text-align: center;
  margin-top: 30px;
}
.font-size {
  font-size: 30px;
}
.react-datepicker__time-container {
  width: 150px;
}
.copy_btn {
  position: relative;
  display: inline-block;
}
.copy_menu {
  position: absolute;
  top: 23px;
  left: -200px;
  background-color: white;
  visibility: hidden;
  z-index: 2;
  width: 355px;
  height: 60px;
  box-shadow: 0px 0px 20px rgb(188, 186, 186);
  border-radius: 10px;
}
.threedotbtn {
  z-index: -1;
  margin: 0px 0px 0px 8px;
  font-size: 20px;
  cursor: pointer;
}

.threedotbtn:hover {
  box-shadow: 0px 0px 10px rgb(163, 162, 162);
}
.threedotbtn:hover ~ .copy_menu {
  visibility: visible;
}
.copy_menu li {
  list-style: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 30px;
  font-size: 15px;
  cursor: pointer;
}
.copy_menu:hover {
  visibility: visible;
}
.copy_menu li:hover {
  color: #0079d2;
}

