@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css);

body {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.3px;
}
.btn-primary {
  background: #222648 !important; 
}
#containerform {
  margin-top: 20px;
  border: 1px solid black;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 6px;
}
.uttam {
  width: 100%;
}

.row {
  margin: 0px;
}

.pad-0 {
  padding: 0px;
}

.mb-35 {
  margin-bottom: 25px;
}

p {
  margin-bottom: 0px;
}

a {
  color: #0079d2;
}

.btn {
  color: #fff !important;

  background: #222648;
  text-transform: uppercase;
  word-wrap: break-word;
  white-space: normal;
  cursor: pointer;
  border: 0;
  border-radius: 3px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 0.6rem 1.5rem;
  font-size: 0.81rem;
  line-height: 1.5;
  /* width: 203px; */
  height: 53px;
}

.btn-right {
  text-transform: uppercase;
  margin-left: auto !important;
  margin-right: 15px;
  display: block;
  font-size: large;
}
@media screen and (min-width: 412px){
  .btn-right{
    text-transform: uppercase;
    margin-left: auto !important;
    margin-right: 110px;
    display: block;
    font-size: large;
    margin-top: 12px;
}
}
@media screen and (min-width: 375px){
  .btn-right{
    text-transform: uppercase;
    margin-left: auto !important;
    margin-right: 50px;
    display: block;
    font-size: large;
    /* margin-top: 12px; */
}
}

.btn-sm {
  padding: 0.3rem 0.85rem;
  font-size: 0.75rem;
  line-height: 1.5;
  letter-spacing: 0.4px;
}

.btn-success {
  color: #fff;
  background-color: #0e9c37;
  border-color: #0e9c37;
}
.btn-success:hover {
  color: #0e9c37 !important;
  background-color: #fff;
  border-color: #fff;
}

.btn-info {
  color: #fff;
  background-color: #222648;
  border-color: #222648;
}
.btn-danger:hover {
  color: #c82333 !important;
  background-color: #fff !important;
  border-color: #fff !important;
}
.btn-info:hover {
  color: #222648 !important;
  background-color: #fff !important;
  border-color: #fff;
}

.btn-primary {
  color: #fff;
  background-color: #0079d2;
  border-color: #0079d2;
}

.form-group {
  margin-bottom: 1.8rem;
}

.form-control {
  font-size: 15px !important;
  height: 38px;
  border-radius: 6px;
  width: 100% !important;
}

.formSelect {
  font-size: 15px !important;
  height: 38px;
  border-radius: 6px;
}

.label-heading {
  margin-bottom: 0.2rem;
  font-size: 14px;
  font-weight: 500;
  color: #545454;
}

.content-wrapper {
  transition: transform 0.3s ease-in-out, margin 0.3s ease-in-out;
  margin-left: 240px;
  z-index: 820;
  margin-top: 60px;
  padding: 20px 5px;
  min-height: 500px;
  background: #f7f7f7;
  /* background: #f6f7fb; */
  overflow: hidden;
}

.text-heading {
  font-size: 18px;
  font-weight: 500;
  color: #333;
  margin-left: 15px;
  margin-bottom: 10px;
  /* margin-top: 20px; */
}

.viewMore {
  float: right;
  margin-left: auto !important;
  margin-right: 15px;
  margin-bottom: 0px;
  cursor: pointer;
  position: relative;
  font-weight: 500;
}

.viewMore a {
  color: #0079d2;
}

.viewMore i {
  font-size: 18px;
  position: relative;
  top: 1px;
}
#buttonItem {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 25px;
  margin-left: -22px;
}
/* .tabBotton {
  background: #fff !important;
  color: #222648 !important;
  border: 1px solid #222648 !important;
  border-radius: 3px !important;
  cursor: pointer !important;
  height: 20px !important;
} */

.tabItem {
  /* float: left; */
  /* background: #fff; */
  color: #222648;
  /* border: 1px solid #222648; */
  /* padding: 7px 10px; */
  /* margin-right: 80px; */
  /* border-radius: 3px; */
  /* font-weight: 300; */
  /* cursor: pointer; */
  height: 38px;

  float: left;
  font-size: large;
  font-weight: bold;
  color: #fff;
  border: 1px solid #222648;
  padding: 15px 17px;
  margin-right: 10px;
  border-radius: 3px;
  cursor: pointer;
  /* box-sizing: border-box; */
  /* width: 203px; */
  height: 53px;
  /* left: 34px;
  top: 198px; */
  background: #0747a6;
  /* border: 1px solid #ffffff; */
  /* border-radius: 12px; */
}

.tabItemActive {
  background: white;
  color: black;

  border-style: double;
  border-color: black;
}

.error {
  color: red;
  font-size: 13px;
  margin-bottom: -20px;
}

.nav-link.active {
  /* background-color: #e3f3ff; */
  background: rgb(0 121 210 / 16%);
  color: #222648;
}

.nav-link:hover {
  /* background-color: #e3f3ff; */
  background: rgb(0 121 210 / 16%);
  color: #888;
}

.nav-link.active i {
  color: #222648;
}

.event__ACCEPTED {
  color: #369803;
  font-weight: bold;
}

.event__REJECTED {
  color: red;
  font-weight: bold;
}

.event__PENDING {
  color: #292713;
  font-weight: bold;
}

.color-status span {
  height: 40px;
  display: inline-block;
  font-weight: bold;
  margin-right: 20px;
}

.color-status span.color-pending {
  color: #292713;
}

.color-status span.color-accepted {
  color: #369803;
}

.color-status span.color-rejected {
  color: red;
}

.rbc-event-label {
  display: none !important;
}

.rbc-event {
  padding: 4px 3px !important;
  background-color: #acd9ff !important;
  border: 1px solid #acd9ff !important;
  border-radius: 0px;
}

.rbc-day-slot .rbc-events-container {
  margin-right: 0px !important;
}

/* 
.event__ACCEPTED,
.event__REJECTED,
.event__PENDING {
  color: #fff;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  letter-spacing: 0.5px;
}

.color-status span {
  height: 40px;
  display: inline-block;
  font-weight: 600;
  margin-right: 15px;
}

.color-status span.color-pending {
  color: #03a9f4;
}

.color-status span.color-accepted {
  color: #4caf50;
}

.color-status span.color-rejected {
  color: #f44336;
}

.rbc-event-label {
  display: none !important;
}

.rbc-event {
  padding: 4px 3px !important;
} */

.exists-acc {
  text-align: center;
  margin-top: 30px;
}
.font-size {
  font-size: 30px;
}
.react-datepicker__time-container {
  width: 150px;
}
.copy_btn {
  position: relative;
  display: inline-block;
}
.copy_menu {
  position: absolute;
  top: 23px;
  left: -200px;
  background-color: white;
  visibility: hidden;
  z-index: 2;
  width: 355px;
  height: 60px;
  box-shadow: 0px 0px 20px rgb(188, 186, 186);
  border-radius: 10px;
}
.threedotbtn {
  z-index: -1;
  margin: 0px 0px 0px 8px;
  font-size: 20px;
  cursor: pointer;
}

.threedotbtn:hover {
  box-shadow: 0px 0px 10px rgb(163, 162, 162);
}
.threedotbtn:hover ~ .copy_menu {
  visibility: visible;
}
.copy_menu li {
  list-style: none;
  width: fit-content;
  line-height: 30px;
  font-size: 15px;
  cursor: pointer;
}
.copy_menu:hover {
  visibility: visible;
}
.copy_menu li:hover {
  color: #0079d2;
}
