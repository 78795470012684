.topCardLeft {
  float: left;
}

.dashimg {
  height: 60vh;
  overflow: hidden;
}

.topCardLeft h5 {
  font-size: 14px;
  margin-bottom: 12px;
  text-align: left;
  color: #9c9c9c;
  font-weight: 400;
}

.topCardLeft h2 {
  font-size: 17px;
  margin-bottom: 10px;
  margin-top: 0;
}

.topCardLeft span {
  font-size: 12px;
  background: #222648 ;
  padding: 5px 10px;
  border-radius: 10px;
  color: #fff;
  letter-spacing: 1px;
}

.topCardRight {
  float: right;
  padding: 15px 0;
  padding-bottom: 0px;
}

.topCardRight img {
  width: 58px;
}

.assignmentDiv h5,
.queriesDiv h5 {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-bottom: 5px;
}

.assignmentDiv p {
  display: inline-block;
  font-size: 14px;
  color: #686f7a;
  margin-bottom: 0;
  margin-top: 3px;
}

.queriesDiv p {
  display: inline-block;
  font-size: 14px;
  color: #686f7a;
  margin-bottom: 0;
  line-height: 27px;
}

.hr {
  margin-top: 10px;
  margin-bottom: 10px;
}

.curriculum {
  margin-right: 10px;
  font-size: 15px;
}

.subject,
.chapter {
  float: left;
  margin-right: 10px;
  font-size: 15px;
}

.queryDiv {
  line-height: 28px;
}

.queryDiv .subject {
  float: none;
  margin-right: 10px;
  font-size: 14px;
  width: 100%;
}

.queryDiv .topic {
  float: none;
  margin-right: 10px;
  font-size: 14px;
}

.queryDiv .chapter {
  margin-right: 10px;
  font-size: 14px;
}

.sessionsDiv h2 {
  font-size: 15px;
  margin-bottom: 5px;
  margin-top: 0;
  color: #333;
}

.sessionsDiv p {
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 0;
}

.sessionsDiv span {
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 10px;
  color: #fff;
  letter-spacing: 1px;
}

.sessionsDiv button {
  margin-top: 12px;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.16), 0 0px 0px 0 rgba(0, 0, 0, 0.12);
  background-color: #ea9f07;
  border-color: #ea9f07;
  width: 100%;
  padding: 5px;
  border-radius: 3px;
}
