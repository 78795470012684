.navbar {
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 60px;
  background: #fff;
  display: flex !important;
  align-items: center;
  box-shadow: 0 0.052083333in 11.25pt -9px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #e6e6e6;
}

.dropdownMenu {
  right: 0px !important;
  left: auto !important;
}

.navbarNav i {
  font-size: 25px;
}
