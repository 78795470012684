#heading {
  color: rgb(79, 157, 202);
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.head {
  font-size: 65px;
}

#head-content {
  font-size: x-large;
  max-width: 550px;
  margin: auto;
  color: rgb(83, 83, 83);
}

.main {
  display: grid;
  grid-template-columns: auto auto auto auto;
  -ms-grid-column-align: center;
  margin-top: 3%;
}

.outer {
  width: 17vw;
}

#blocks {
  display: flex;
  margin: auto;
  height: 54vh;
  margin-top: 22%;
}

.block {
  border-top: 3px solid rgb(79, 157, 202);
  padding: 2%;
  padding-top: 12%;
  margin-top: 1%;
  height: 45.7vh;
  width: 20vw;
}

.block-heading {
  height: 16vh;
}

.info {
  margin-top: 4%;
  font-size: x-large;
  height: 10vh;
}

.amount {
  font-size: 55px;
  margin-top: 10%;
  margin-bottom: 6%;
  color: rgb(79, 157, 202);
}

#button {
  width: 100%;
  color: red;
  margin: auto;
}

@media only screen and (max-width: 1280px) {
}

@media only screen and (max-width: 1024px) {
  .block-heading {
    font-size: 28px;
  }

  .info {
    font-size: large;
  }

  .amount {
    font-size: 50px;
    margin-top: 10%;
  }
}

@media only screen and (max-width: 912px) {
  .outer {
    width: 25vw;
  }

  .main {
    display: grid;
    grid-template-columns: auto auto auto;
  }

  #blocks {
    margin-bottom: -80%;
  }

  .block {
    height: 25.7vh;
  }

  .block-heading {
    font-size: 28px;
    height: 8vh;
  }

  .info {
    height: 7vh;
  }

  .amount {
    font-size: 50px;
    margin-top: 7%;
  }
}

@media only screen and (max-width: 820px) {
  .block-heading {
    font-size: 24px;
    height: 8vh;
  }
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 540px) {
  .outer {
    width: 44vw;
  }

  .main {
    display: grid;
    grid-template-columns: auto auto;
    -ms-grid-column-align: center;
    margin-top: 3%;
  }

  #blocks {
    margin-bottom: 0%;
  }

  .block {
    height: 20.7vh;
    width: 50vw;
  }

  .block-heading {
    font-size: 28px;
    height: 8vh;
  }

  .info {
    margin-top: 20%;
    height: 12vh;
    font-size: x-large;
  }

  .amount {
    font-size: 50px;
    margin-top: 10%;
    margin-bottom: 20%;
  }
}

@media only screen and (max-width: 414px) {
}

@media only screen and (max-width: 375px) {
  #head-content {
    font-size: large;
  }

  .main {
    display: grid;
    grid-template-columns: auto;
  }

  .outer {
    width: 80vw;
  }

  #blocks {
    margin-bottom: 25%;
  }

  .block {
    width: 80vw;
  }



}
