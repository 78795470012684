.sessionForm .label-heading {
  width: 100px;
  float: left;
  position: relative;
  top: 8px;
}

.sessionForm .form-control,
.sessionForm .react-datepicker-wrapper {
  font-size: 15px !important;
  width: 70% !important;
}

.sessionForm .react-datepicker-wrapper input {
  width: 100% !important;
}

.sessionForm .error {
  margin-left: 100px;
}
