.LabDiv h5,
.queriesDiv h5 {
  font-size: 15px;
  font-weight: 500;
  color: #000000;
}

.LabDiv p {
  display: inline-block;
  font-size: 14px;
  color: #686f7a;
  margin-bottom: 0;
  margin-top: 3px;
}

.LabSummary {
  background: #fff;
  width: 100%;
  float: left;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #efefef;
  box-shadow: none;
  transition: all 0.2s ease-in-out;
}

.LabSummary p {
  display: block;
  padding: 12px 10px;
  text-align: left;
  border-bottom: 1px dashed #efefef;
  margin: 0px;
}

.LabSummary p:last-child {
  padding-bottom: 5px;
  border-bottom: 0;
}

.LabSummary .badge {
  float: right;
  padding: 6px 8px;
  font-weight: 400;
  font-size: 13px;
  width: 30px;
}

.testType {
  width: 110px;
  float: left;
  background: #fff;
  color: #0077cd;
  padding: 7px 10px;
  margin-right: 10px;
  border-radius: 3px;
  font-weight: 500;
  cursor: pointer;
}

.active {
  background: #0077cd;
  color: #fff;
}

.LabResult {
  display: block;
  background: transparent;
  margin-top: 8px;
  padding: 3px 10px;
  color: #fff;
  border-radius: 3px;
  text-align: center;
}

.labPass {
  background: #4caf50;
}

.labPassAvg {
  background: #ffbf00;
}

.labFail {
  background: #f71b1b;
}
