.progress {
  height: 10px;
  border: 1px solid #f1f1f1;
  background: #e0e0e0;
  border-radius: 10px;
}
.dashimg {
  height: 60vh;
  overflow: hidden;
}

.progressBar {
  height: 8px;
  border-radius: 10px;
}

.chaptersDiv table {
  background: #fff;
}

.chaptersDiv table td:nth-child(2) {
  font-weight: 500;
  width: 200px;
}

.chaptersDiv table td:nth-child(3) {
  width: 40%;
}

.modalBody {
  min-height: 400px;
}

.notAvailable {
  text-align: center;
  width: 100%;
  padding-top: 150px;
  font-size: 18px;
  font-weight: 500;
}
.txt {
  display: flex;
  /* position: absolute; */
  width: 100%;
  height: 244px;
  left: 63px;
  top: 437px;

  background: #ecf3ff;
  border-radius: 6px;
}

.box {
  /* box-sizing: border-box; */

  /* position: absolute; */
  width: auto;
  height: auto;
  left: 34px;
  top: 341px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
  margin-bottom: 20px;

  border: 1px solid #000000;
  border-radius: 6px;
}

@media screen and (min-width: 1200px) {
  .txt {
    width: 100%;
  }
}

@media screen and (min-width: 1100) {
  .txt {
    width: 900px;
  }
}
@media screen and (max-width: 750) {
  .txt {
    width: 200px;
  }
}
