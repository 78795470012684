.progress {
  height: 10px;
  border: 1px solid #f1f1f1;
  background: #e0e0e0;
  border-radius: 10px;
}

.progressBar {
  height: 8px;
  border-radius: 10px;
}

.chaptersDiv table {
  background: #fff;
}

.chaptersDiv table td:nth-child(2) {
  font-weight: 500;
  width: 200px;
}

.chaptersDiv table td:nth-child(3) {
  width: 40%;
}

.modalBody {
  min-height: 400px;
}

.notAvailable {
  text-align: center;
  width: 100%;
  padding-top: 150px;
  font-size: 18px;
  font-weight: 500;
}
