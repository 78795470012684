.card {
  width: 40%;
  text-align: center;
}
.cardImg {
  height: 100;
}
.image {
  margin: "23px auto";

  display: "block";
}
.cardContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100vh;
  padding-top: 8%;
}

