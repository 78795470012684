.form-heading {
  font-size: 23px;
  text-align: center;
  margin-bottom: 15px;
  margin-top: 15px;
  font-weight: 600;
  width: 100%;
}

.ck-content {
  min-height: 150px;
  height: 150px !important;
  overflow-y: auto !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.add-btn-div {
  width: 100%;
  padding: 15px 0;
  margin-top: -60px;
  text-align: end;
}

.add-btn {
  float: right;
}
.add-btn-1{
  float: left; 
}

.text-view {
  width: 100%;
  border: 1px solid #efecec;
  min-height: 50px;
  vertical-align: middle;
  border-bottom: 0px;
  padding: 12px;
  display: flex;
}

.text-view:last-child {
  border-bottom: 1px solid #efecec;
}

.text-view label {
  font-weight: 500;
  color: #908e8e;
  display: block;
  margin: 2px;
  font-size: 14px;
  float: left;
  width: 35%;
}

.text-view span {
  font-weight: 400;
  font-size: 15px;
  width: 65%;
}

.text-view-long span {
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
}

.closePage {
  clear: both;
  width: 100%;
  background: #ffffff;
  padding: 10px 15px;
  margin-bottom: 20px;
  border-bottom: 1px solid #dcdcdc;
  margin-top: -18px;
}

.closePage h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: 5px;
  float: left;
}

.closePage .btn {
  float: right;
  margin-top: 2px;
  width: 100px;
  letter-spacing: 1px;
  font-weight: 500;
}

.table {
  margin-bottom: 0px;
}

.table > thead > tr {
  background-color: #ececec !important;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 0px;
}

.table td {
  vertical-align: middle !important;
}

.table td:last-child {
  width: 150px;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 0px solid #dee2e6;
  font-weight: 600;
  font-size: 15px;
}

.question-list {
  padding: 20px;
  line-height: 30px;
}

.la-check-circle {
  color: green;
  font-size: 20px;
  position: relative;
  top: 2px;
}

i.las.la-times-circle {
  color: red;
  font-size: 20px;
  position: relative;
  top: 2px;
}
.question-list .ans {
  margin-bottom: 5px;
}

.form-control {
  font-size: 20px !important;
}

.staffSelection {
  position: relative;
  top: 40px;
  left: 15px;
}

.eventModal .text-view label {
  width: 170px;
  color: #000;
}

.eventModal .text-view {
  width: 100%;
  border: 0px solid #efecec;
  min-height: 30px;
  vertical-align: middle;
  border-bottom: 0px;
  padding: 7px;
}

.eventModal .text-view span {
  font-weight: 400;
  font-size: 14px;
}

.eventModal .text-view ol {
  margin-left: 150px;
  margin-bottom: 0px;
}

.eventModal .modal-title {
  font-size: 18px;
}
