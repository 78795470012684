.sessionsDiv h2 {
  font-size: 15px;
  margin-bottom: 5px;
  margin-top: 0;
  color: #333;
}
.dashimg {
  height: 60vh;
  overflow: hidden;
}
.sessionsDiv p {
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 0;
}

.sessionsDiv span {
  font-size: 12px;

  padding: 5px 10px;
  border-radius: 10px;
  color: #fff;
  letter-spacing: 1px;
}

.sessionsDiv button {
  margin-top: 12px;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.16), 0 0px 0px 0 rgba(0, 0, 0, 0.12);
  background-color: #ea9f07;
  border-color: #ea9f07;
  width: 100%;
  padding: 5px;
  border-radius: 3px;
}

.sessionPreviewDiv {
  background: #fff;
  margin-bottom: 20px;
  float: left;
  width: 100%;
  border-radius: 6px;
  border: 2px solid #eee;
  padding: 15px;
  box-shadow: 0 0 11px -6px rgb(187 187 187 / 65%);
  font-size: 14px;
}

.sessionPreviewDiv table th {
  border: 0px;
}

.sessionPreviewDiv table td {
  border: 0px;
}

.sessionPreviewDiv h2 {
  font-size: 22px;
  margin-bottom: 17px;
  font-weight: 600;
  color: #333;
  text-align: center;
}

.testBack {
  position: absolute;
  right: 10px;
}

.testStart {
  position: absolute;
  right: 10px;
  bottom: 20px;
}
